<template>
    <div>
        <div class="p-5 bg-white rounded-lg">
            <UnBindSocialMedia
                :haveBindSocialMedia="bindSocialMedias"
                :unBindSocialMedia="unBindSocialMedias"
                @callback="setDefault"
            />
        </div>
        <div class="p-5 mt-10 mb-5 bg-white rounded-lg">
            <div class="flex">
                <h2
                    class="flex-1 mb-3 text-base font-medium md:text-2xl md:mb-10"
                >
                    簡訊通知
                </h2>
                <el-switch
                    v-model="form.openSMS_Notice"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="1"
                    :inactive-value="0"
                    @change="updateGetSMSNotice"
                >
                </el-switch>
            </div>
            <p class="my-2 text-xs text-gray-300 md:text-base">
                服務商確認會員發起的預訂時，會以傳送簡訊的方式通知此會員，會員無須為簡訊負擔額外的費用。
            </p>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
// 取消綁定第三方組件
import UnBindSocialMedia from "@/components/UnBindSocialMedia.vue";
export default {
    components: {
        UnBindSocialMedia,
    },
    data() {
        return {
            form: {
                openSMS_Notice: false,
            },
            loading: false,
            // 已綁定第三方帳號資料
            bindSocialMedias: {},
            // 未綁定第三方帳號資料
            unBindSocialMedias: {},
        };
    },
    methods: {
        ...mapActions("apiStore", ["errorCallback"]),
        async setDefault() {
            try {
                const { data } = await this.$api.GetUserDataApi(
                    this.$route.params.id
                );
                const bindSocialMedia = {};
                const unBindSocialMedia = {};
                // 判斷第三方登入使用者是否有資料
                const filterSocialMedias = [
                    "facebook_user",
                    "google_user",
                    "line_user",
                    "apple_user",
                ];
                filterSocialMedias.forEach((socialMedia, index) => {
                    if (!this.$isEmpty(data[socialMedia])) {
                        bindSocialMedia[socialMedia] = data[socialMedia];
                        bindSocialMedia[socialMedia]["index"] = index;
                    }
                });
                filterSocialMedias.forEach((socialMedia, index) => {
                    if (this.$isEmpty(data[socialMedia])) {
                        unBindSocialMedia[socialMedia] = {};
                        unBindSocialMedia[socialMedia]["index"] = index;
                    }
                });
                this.bindSocialMedias = bindSocialMedia;
                this.unBindSocialMedias = unBindSocialMedia;
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得個人資料失敗",
                });
            }
        },
        // 取得是否收到簡訊通知設定值
        async getSettingsApi() {
            try {
                const {
                    data: { receiveDatingAcceptedSMS },
                } = await this.$api.GetSettingsApi(this.$route.params.id);
                this.$set(
                    this.form,
                    "openSMS_Notice",
                    receiveDatingAcceptedSMS
                );
            } catch (err) {
                this.$set(
                    this.form,
                    "openSMS_Notice",
                    this.form.openSMS_Notice === 1 ? 0 : 1
                );
                this.errorCallback({ err });
            }
        },
        // 取得是否收到簡訊通知設定值
        async updateGetSMSNotice() {
            try {
                await this.$api.UpdateProfileSetting(this.$route.params.id, {
                    receiveDatingAcceptedSMS: this.form.openSMS_Notice,
                });
            } catch (err) {
                this.$set(
                    this.form,
                    "openSMS_Notice",
                    this.form.openSMS_Notice === 1 ? 0 : 1
                );
                this.errorCallback({ err });
            }
        },
    },
    created() {
        this.getSettingsApi();
    },
    activated() {
        this.getSettingsApi();
    },
};
</script>
