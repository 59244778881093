<template>
    <div class="relative grid grid-cols-12 gap-4 px-5">
        <div class="col-span-3 px-2">
            <AvatarUpload
                ref="avatarUpload"
                v-loading="loading"
                :showTip="false"
                :defaultImg="defaultAvatar"
                :useDialog="true"
                @onCropped="avatarCroppedData"
            />
            <el-form ref="form" class="mt-10" :model="form" :rules="rules">
                <el-form-item class="w-full">
                    <label class="block mb-0 text-gray-400"> 實名驗證 </label>
                    <p class="-mt-2 text-base">{{ authLevel | statusText }}</p>
                    <Btn class="mt-4 w-[50px]" @onClick="openVerification()"
                        >查看審核紀錄</Btn
                    >
                </el-form-item>
                <el-form-item class="w-full" prop="role">
                    <div>帳號狀態</div>
                    <label
                        class="flex items-center mb-0 leading-none text-gray-400"
                    >
                        <el-select
                            v-model="form.status"
                            class="flex-1"
                            :disabled="form.status === -2"
                            placeholder="請選擇狀態"
                        >
                            <el-option
                                v-for="(option, index) in statusOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value"
                            >
                            </el-option>
                        </el-select>
                    </label>
                </el-form-item>
                <el-form-item class="w-full" prop="role">
                    <div>即刻快閃現金付款使用狀態</div>
                    <label
                        class="flex items-center mb-0 leading-none text-gray-400"
                    >
                        <el-select
                            v-model="form.enablePayByCash"
                            class="flex-1"
                            placeholder="請選擇狀態"
                        >
                            <el-option
                                v-for="(
                                    option, index
                                ) in enablePayByCashOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value"
                            >
                            </el-option>
                        </el-select>
                    </label>
                </el-form-item>
                <el-form-item class="w-full" prop="disable_dating_demand">
                    <div>開即刻快閃權限</div>
                    <label
                        class="flex items-center mb-0 leading-none text-gray-400"
                    >
                        <el-select
                            v-model="form.disable_dating_demand"
                            class="flex-1"
                            placeholder="請選擇狀態"
                        >
                            <el-option
                                v-for="(option, index) in enableDemandOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value"
                            >
                            </el-option>
                        </el-select>
                    </label>
                </el-form-item>
                <el-form-item
                    v-if="
                        salesmanStatus !== null &&
                        ![0, -1].includes(salesmanStatus)
                    "
                    class="w-full"
                >
                    <div>城市推廣人</div>
                    <label
                        class="flex items-center mb-0 leading-none text-gray-400"
                    >
                        <el-select
                            v-model="salesmanStatus"
                            class="flex-1"
                            placeholder="請選擇狀態"
                        >
                            <el-option
                                v-for="(option, index) in salesmanStatusOptions"
                                :key="index"
                                :label="option.label"
                                :value="option.value"
                            >
                            </el-option>
                        </el-select>
                    </label>
                    <div class="text-gray-400">城市推廣人邀請碼</div>
                    <label
                        class="flex items-center mb-0 leading-none text-gray-400"
                    >
                        <el-input
                            v-model="salesmanCode"
                            class="flex-1"
                            show-word-limit
                            placeholder="請輸入代碼"
                            minlength="2"
                            maxlength="10"
                        >
                        </el-input>
                    </label>
                </el-form-item>
            </el-form>
            <div
                v-if="memberData.promoter !== null"
                class="text-sm text-gray-400"
            >
                <div class="mb-2">連結的城市推廣人</div>
                <div class="flex items-center">
                    <div class="flex-grow-0">
                        <Avatar
                            :size="['w-10', 'h-10']"
                            :backgroundImg="
                                memberData.promoter.user.thumbnails.avatar[
                                    '360x360'
                                ]
                            "
                        />
                    </div>
                    <a
                        :href="`/#/user_list/${
                            salesmanIsProvider
                                ? 'provider_update'
                                : 'member_update'
                        }/${memberData.promoter.user.id}`"
                        target="salesman"
                        class="ml-5 text-red-500 underline"
                    >
                        {{ memberData.promoter.user.name }}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-span-9 px-2">
            <el-form ref="form" :model="form" :rules="rules">
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="phone"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            手機號碼
                            <el-input
                                v-model="form.phone"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="password"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            密碼
                            <div class="relative">
                                <el-input
                                    v-model="form.password"
                                    class="w-full"
                                    :type="showPassword ? 'text' : 'password'"
                                ></el-input>
                                <!-- 眼睛開關 用來顯示密碼使用 -->
                                <span
                                    class="absolute text-xl cursor-pointer right-2 top-1"
                                    @click="showPassword = !showPassword"
                                >
                                    <i
                                        v-if="showPassword"
                                        class="icofont-eye-alt"
                                    ></i>
                                    <i
                                        v-if="!showPassword"
                                        class="icofont-eye-blocked"
                                    ></i>
                                </span>
                            </div>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="name"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            暱稱
                            <el-input
                                v-model="form.name"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="real_name"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            真實姓名
                            <el-input
                                v-model="form.real_name"
                                class="w-full"
                            ></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="gender"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            性別
                            <el-select
                                v-model="form.gender"
                                class="w-full"
                                placeholder="請選擇性別"
                            >
                                <el-option
                                    v-for="(option, index) in genderOptions"
                                    :key="index"
                                    :label="option.label"
                                    :value="option.value"
                                >
                                </el-option>
                            </el-select>
                        </label>
                    </el-form-item>
                    <el-form-item
                        class="w-full col-span-2 mb-3 md:col-span-1"
                        prop="birthday"
                    >
                        <label class="mb-0 leading-none text-gray-400">
                            生日
                            <el-date-picker
                                v-model="form.birthday"
                                class="w-full"
                                type="date"
                                :default-value="defaultValue()"
                                :picker-options="pickerOptions"
                                placeholder="請選擇日期"
                            >
                            </el-date-picker>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <div class="w-full col-span-2 mb-3 md:col-span-1">
                        <el-form-item prop="email">
                            <label class="mb-0 leading-none text-gray-400">
                                Email
                                <el-input
                                    v-model="form.email"
                                    placeholder="請輸入電子郵件"
                                    class="w-full"
                                />
                            </label>
                        </el-form-item>
                        <el-checkbox-group
                            v-model="form.marketing_notification"
                        >
                            <el-checkbox
                                class="leading-none text-gray-300"
                                :label="0"
                                >我不想收到&nbsp;City&nbsp;Banana&nbsp;相關的行銷資訊</el-checkbox
                            >
                        </el-checkbox-group>
                    </div>
                    <el-form-item prop="government_cert">
                        <label class="block mb-0 text-gray-400">身份證</label>
                        <div class="flex">
                            <el-select
                                v-model="form.government_cert"
                                class="rounded-tr-none rounded-none max-w-[120px]"
                                placeholder="證件型態"
                            >
                                <el-option
                                    v-for="(item, index) in certOptions"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-form-item class="flex-1" prop="government_id">
                                <el-input
                                    v-model="form.government_id"
                                    placeholder="請輸入身份證"
                                    class="w-full"
                                />
                            </el-form-item>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex items-end mt-2 mb-3">
                    <el-form-item prop="district">
                        <label class="leading-none text-gray-400">
                            <span class="block mb-3">所在地區</span>
                            <el-select
                                v-model="form.district"
                                class="max-w-[150px] rounded-tr-none rounded-none"
                                placeholder="請選擇地區"
                            >
                                <el-option
                                    v-for="(item, key) in areaOptions"
                                    :key="key"
                                    :label="item.name"
                                    :value="key"
                                >
                                </el-option>
                            </el-select>
                        </label>
                    </el-form-item>
                    <el-form-item class="flex-1" prop="address">
                        <el-input v-model="form.address"> </el-input>
                    </el-form-item>
                </div>
            </el-form>
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                @submit.native.prevent
            >
                <span class="block mb-3 text-sm leading-none text-gray-400"
                    >客服註記</span
                >
                <slot name="provider-remarks"></slot>
                <el-form-item class="w-full">
                    <div v-loading="sendNoteloading" class="flex items-end">
                        <label class="flex-1">
                            <el-input
                                v-model="note"
                                class="w-full"
                                placeholder="請輸入註記內容"
                            >
                            </el-input>
                        </label>
                        <btn
                            v-permission="['update']"
                            color="border-red-500 border text-red-500"
                            size="md:text-sm text-xs px-2 py-2 rounded max-w-[150px]"
                            customClass="ml-2"
                            @onClick="sendNote(note)"
                        >
                            <!-- <i class="text-lg el-icon-plus"></i> -->
                            送出註記
                        </btn>
                        <!-- <button
                            class="border-orange-600 border px-5 h-[40px] ml-2 rounded-lg text-orange-600 transition duration-200 hover:bg-orange-500 hover:text-white md:text-sm text-xs"
                            @click.prevent="sendNote(note)">
                            <i class="text-lg el-icon-plus"></i>
                            送出註記
                        </button> -->
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
// 頭像組件
import Avatar from "@/components/Avatar.vue";
// 上傳頭像組件
import AvatarUpload from "../../../../../components/AvatarUpload.vue";
// 使用語系檔取得地區資料
import { areas } from "../../../../../langs/tw.json";
/**
 * formNickNameCheck: 驗證暱稱是否有超過 10個字 以及 不可輸入 emoji 符號
 * formCheckUserAge: 判斷是否18歲
 * formEmailCheckAlready: 判斷此信箱是否有人使用
 */
import {
    formCheckUserAge,
    formNickNameCheck,
    formEmailCheckAlready,
} from "../../../../../service/formRules";
// 判斷是否小於 18 歲日期 disabled 跟 未來時間 disabled 以及預設日期為 18歲當年月份
import datepickerMixin from "@/service/datepickerMixin";
// 導入 firebase 更新聊天室全部聊天對象資料方法
import firebaseChatMixin from "@/service/firebaseChatMixin";
// 導入 實名驗證對應狀態名詞
import AuthenticationCheck from "@/service/authenticationCheckMixin";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
import { isEmpty } from "@/service/anyService";

export default {
    mixins: [datepickerMixin, firebaseChatMixin, AuthenticationCheck],
    components: {
        Avatar,
        AvatarUpload,
        Btn,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為更新
        isUpdate: {
            type: Boolean,
            default: false,
        },
        // 判斷是否為 provider
        isProvider: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("memberStore", ["memberData"]),
        salesmanIsProvider() {
            if (this.memberData.promoter !== null) {
                return this.memberData.promoter.user.role !== 0 ? true : false;
            }
            return false;
        },
    },
    data() {
        return {
            form: {
                marketing_notification: [],
                setting: {},
            },
            rules: {
                phone: [
                    {
                        required: true,
                        message: "手機為必填",
                        trigger: "blur",
                    },
                    {
                        pattern: /8869[0-9]{8}$/,
                        message: `手機格式錯誤`,
                        trigger: "blur",
                    },
                ],
                // real_name: [
                //     {
                //         required: true,
                //         message: "真實姓名為必填",
                //         trigger: "blur"
                //     },
                //     {
                //         min: 2,
                //         message: "真實姓名需兩字以上",
                //         trigger: "blur"
                //     }
                // ],
                name: [
                    {
                        required: true,
                        message: "暱稱為必填",
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: this.formNickNameCheck,
                        trigger: "blur",
                    },
                ],
                birthday: [
                    // {
                    //     required: true,
                    //     message: "生日為必填",
                    //     trigger: "change",
                    // },
                    {
                        validator: this.formCheckUserAge,
                        trigger: "change",
                    },
                ],
                gender: [
                    {
                        required: true,
                        message: "性別為必填",
                        trigger: ["change", "blur"],
                    },
                ],
                // address: [
                //     {
                //         required: true,
                //         message: "地址為必填",
                //         trigger: "blur"
                //     }
                // ],
                // district: [
                //     {
                //         required: true,
                //         message: "所在區域為必填",
                //         trigger: ["change", "blur"]
                //     }
                // ],
                email: [
                    {
                        required: true,
                        validator: this.formEmailCheckExampleDomain,
                        trigger: "blur",
                    },
                    {
                        required: true,
                        validator: this.formEmailCheckAlready,
                        trigger: "blur",
                    },
                ],
                // government_id: [
                //     { required: true, message: "身份證字號為必填", trigger: "blur" }
                //     // 身份證字號驗證
                //     // { required: true, pattern: /^[A-Z][12][0-9]{8}$/, message: "請輸入正確的身份證字號", trigger: "blur" }
                // ]
            },
            // 區域選擇,
            areaOptions: areas,
            // 選擇性別資料
            genderOptions: [
                {
                    label: "男",
                    value: "male",
                },
                {
                    label: "女",
                    value: "female",
                },
            ],
            // 身份認證類別選擇
            certOptions: [
                {
                    label: "身份證",
                    value: 0,
                },
                {
                    label: "護照",
                    value: 1,
                },
                {
                    label: "居留證",
                    value: 2,
                },
            ],
            // 帳號狀態
            statusOptions: [
                { label: "使用中", value: 0 },
                { label: "停權", value: -1 },
                { label: "永久停權", value: -2 },
            ],
            // 城市推廣人狀態
            salesmanStatusOptions: [
                { label: "使用中", value: 1 },
                // { label: "未通過", value: -1 },
                { label: "停權", value: -2 },
            ],
            // 判斷是否開啟即刻快閃現金單功能
            enablePayByCashOptions: [
                { label: "開啟", value: 1 },
                { label: "關閉", value: 0 },
            ],
            // 判斷是否開啟即刻快閃開單功能
            enableDemandOptions: [
                { label: "開啟", value: 0 },
                { label: "關閉", value: 1 },
            ],
            // 判斷 input 輸入框使用 text 或者 password模式
            showPassword: false,
            // 判斷是否啟用旋轉動畫
            loading: false,
            // 預設大頭照
            defaultAvatar: null,
            // 客服註記
            note: null,
            // 發送客服註記旋轉動畫
            sendNoteloading: false,
            // 城市推廣人狀態
            salesmanStatus: null,
            // 城市推廣人代碼
            salesmanCode: null,
        };
    },
    methods: {
        ...mapMutations("memberStore", ["setMemberData"]),
        ...mapActions("apiStore", ["errorCallback"]),
        // 判斷email 是否在特定網域
        formEmailCheckExampleDomain(rule, value, callback) {
            if (isEmpty(value)) {
                return callback();
            }
            // 需過濾的網址
            const filterDomains = ["example"];
            // 取得輸入匡eamil值 並且取出 @ 之後的網址 接這在取出 . 之前的字串
            const emailDomain = value.split("@")[1].split(".")[0];
            if (filterDomains.includes(emailDomain)) {
                return callback(new Error("email 格式錯誤"));
            }
            return callback();
        },
        // 判斷信箱是否重複
        async formEmailCheckAlready(rule, value, callback) {
            const { success, message } = await formEmailCheckAlready(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        // 暱稱驗證
        async formNickNameCheck(rule, value, callback) {
            const { success, message } = await formNickNameCheck(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        // 判斷是否18歲
        async formCheckUserAge(rule, value, callback) {
            const { success, message } = await formCheckUserAge(value);
            if (success) {
                return callback();
            }
            return callback(new Error(message));
        },
        /**
         * 裁切成功後回傳財切檔案
         * @param { type Blob(檔案) } val 裁切圖片檔
         */
        async avatarCroppedData(val) {
            this.loading = true;
            try {
                const result = new FormData();
                result.append("image", val);
                const { data } = await this.$api.UploadAvatarApi(
                    this.$route.params.id,
                    result
                );
                // // 重新取得使用者資料
                const userData = await this.getData(true);
                // // 更新firebase 聊天對象中屬於目前修改對象的資料
                await this.updateFirebaseChatRoomUserData(
                    userData.banana_id,
                    userData
                );
                // 設定上傳後的圖片網址到 頭像組件中
                this.$refs.avatarUpload.setCropped(data.avatar);
                this.defaultAvatar =
                    data.thumbnails === undefined
                        ? data.avatar
                        : data.thumbnails.avatar["360x360"];
                this.loading = false;
                this.$message({
                    type: "success",
                    message: "上傳頭像成功",
                });
                this.$emit("updateAvatar", data.avatar);
            } catch (err) {
                this.loading = false;
                // 上傳失敗時要清除上傳頭像組件資料
                this.$refs.avatarUpload.handleRemove();
                this.$message({
                    type: "error",
                    message: "上傳頭像失敗",
                });
            }
        },
        /**
         * 取得當前會員資料
         * @param { type Boolean(布林值) } isUploadAvatar 判斷是上傳頭像時 不更新服務商store資料
         */
        async getData(isUploadAvatar = false) {
            try {
                // 取得服務商資料
                const { data } = await this.$api.GetUserDataApi(
                    this.$route.params.id
                );
                this.loading = false;
                // 不是上傳頭像時設定會員資料 (因為 watch機制影響 上傳頭像會清空服務商詳細資料表單中值)
                if (!isUploadAvatar) {
                    this.setMemberData(data);
                }
                return data;
            } catch (err) {
                this.loading = false;
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得使用者資料失敗",
                });
            }
        },
        // 設定表單預設值
        setDefault(memberData) {
            if (memberData === undefined) {
                return;
            }
            // 設定預設大頭照
            this.defaultAvatar =
                memberData.thumbnails === undefined
                    ? memberData.avatar
                    : memberData.thumbnails.avatar["360x360"];
            // 判斷沒有拿到 memberData 不執行
            if (memberData.status === undefined) {
                return;
            }
            this.form = {
                status: memberData.status === 2 ? 0 : memberData.status,
                role: memberData.role,
                phone: memberData.phone,
                name: memberData.name,
                real_name: memberData.real_name,
                gender: memberData.gender,
                birthday: memberData.birthday ?? null,
                email: memberData.email,
                district: memberData.district,
                address: memberData.address,
                setting: memberData.setting,
                // 判斷是否開啟現金付款
                enablePayByCash: !this.$isEmpty(memberData.setting)
                    ? memberData.setting.enablePayByCash
                    : 0,
                // 判斷是否開啟即刻快閃權限
                disable_dating_demand: !this.$isEmpty(memberData.setting)
                    ? memberData.setting.disable_dating_demand ?? 0
                    : 0,
                government_id: memberData.government_id,
                government_cert: memberData.government_cert ?? null,
            };
            // 設定實名驗證等級
            this.authLevel = memberData.status;
            this.$set(this.form, "marketing_notification", [
                memberData.marketing_notification,
            ]);

            // profile 表單資料回傳給父組件
            this.$emit("update:profileFormData", {
                ...this.form,
                setting: {
                    ...this.form.setting,
                },
            });
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            // 判斷沒有有上傳大頭照
            if (this.defaultAvatar === "/img/avatar/avatar-icon.svg") {
                // profile 表單驗證失敗時回傳給父組件
                this.$emit("update:profileFormValidate", false);
                return this.$message({
                    type: "error",
                    message: "請上傳大頭照",
                });
            }
            // 當選擇永久停權狀態時要執行
            if (this.form.status === -2 && this.memberData.status !== -2) {
                // 判斷是否確認更改使用者狀態為永久停權
                const isSuspension = await this.isSuspension();
                // 當選擇取消永久停權時 不在往下執行更新
                if (!isSuspension) {
                    // profile 表單驗證失敗時回傳給父組件
                    this.$emit("update:profileFormValidate", false);
                    return;
                }
            }
            /**
             * 判斷是否有輸入非英文數字以外的文字
             */
            const regex = /[A-Za-z0-9]/gm;
            if (!regex.test(this.salesmanCode) && !isEmpty(this.salesmanCode)) {
                // profile 表單驗證失敗時回傳給父組件
                this.$emit("update:profileFormValidate", false);
                return this.$message({
                    type: "error",
                    message: "邀請碼格式錯誤",
                });
            }
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                /**
                 * 判斷目前是否有勾選不想要收到網頁廣告信 因為有勾選值為 false 特列 因此需要特別處理
                 * 原本值為陣列 取消勾選後 裡面值為空陣列 因此判斷空陣列傳送值 為 true
                 * 有值時傳送值為 false
                 */
                if (this.form.marketing_notification.length <= 0) {
                    this.form.marketing_notification = 1;
                } else {
                    this.form.marketing_notification = 0;
                }
                if (
                    !isEmpty(this.form.birthday) ||
                    this.form.birthday instanceof Date
                ) {
                    // 轉換生日日期格式
                    this.$set(
                        this.form,
                        "birthday",
                        this.$moment(this.form.birthday).format("YYYY-MM-DD")
                    );
                }
                // 判斷 是否可開啟現金付款
                this.form.setting = {
                    ...this.form.setting,
                    enablePayByCash: this.form.enablePayByCash,
                    disable_dating_demand: this.form.disable_dating_demand,
                };
                // profile 表單資料回傳給父組件
                this.$emit("update:profileFormData", this.form);
                // profile 表單驗證成功時回傳給父組件
                this.$emit("update:profileFormValidate", true);
            } catch (err) {
                // profile 表單驗證失敗時回傳給父組件
                this.$emit("update:profileFormValidate", false);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        /**
         * 發送客服註記
         * @param { type String (字串) } val 註記內容
         */
        async sendNote(val) {
            this.sendNoteloading = true;
            try {
                await this.$api.CreateNotesApi(this.$route.params.id, val);
                this.sendNoteloading = false;
                this.note = null;
                this.$message({
                    type: "success",
                    message: "新增客服註記成功",
                });
                // 新增完後 回傳給父組件 取得最新列表
                this.$emit("updateNotesList", true);
            } catch (err) {
                this.sendNoteloading = false;
                this.$message({
                    type: "error",
                    message: "新增客服註記失敗",
                });
            }
        },
        // 判斷選擇永久停權時
        async isSuspension() {
            try {
                const opts = {
                    title: "確認是否永久停權此用戶",
                    message: "要執行此操作，請在下面的表格中輸入 CONFIRM。",
                };
                await this.$pop.popConfirmType(opts);
                // // 重新取得使用者資料
                const userData = await this.getData(true);
                // // 更新firebase 聊天對象中屬於目前修改對象的資料
                await this.updateFirebaseChatRoomUserData(
                    userData.banana_id,
                    userData
                );

                return true;
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取消此操作",
                });
                return false;
            }
        },
        // 導到實名驗證頁
        openVerification() {
            window.open(
                `/#/user_list/verification_detail/${this.$route.params.id}`
            );
        },
        // 取得城市推廣人狀態
        async getSalesmanStatus() {
            this.loading = true;
            try {
                const {
                    data: { status, invitation_code },
                } = await this.$api.GetApplyCityPlanSalesmanApi(
                    this.$route.params.id
                );
                this.salesmanStatus = status;
                this.salesmanCode = invitation_code;
            } catch (err) {
            } finally {
                this.loading = false;
            }
        },
        // 更新城市推廣人狀態
        async updateSalesmanStatus() {
            // 沒有城市推廣人資料時不往下執行
            if (this.salesmanStatus === null) {
                return;
            }
            try {
                await this.$api.UpdateCityPlanSalesmanStatusApi({
                    userId: this.$route.params.id,
                    status: this.salesmanStatus,
                    invitation_code: this.salesmanCode,
                });
            } catch (err) {
                this.errorCallback({ err });
            }
        },
    },
    watch: {
        memberData(val) {
            this.setDefault(val);
            // 取得城市推廣人資料
            this.getSalesmanStatus();
        },
    },
    created() {
        // 設定表單資料內容
        this.setDefault(this.memberData);
        this.$nextTick(() => {
            this.$refs["form"].clearValidate();
        });
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-date-editor {
    @apply w-full;
}
</style>
